import React from "react"
import { Link } from "gatsby"
import Layout from '../../components/layout'




const IncusPassesSRRMDR = ({ data }) => {
  return (
    <Layout pageTitle="INCUS | Extreme Weather in the News">
      <h1>INCUS Passes SRR/MDR</h1>
      <h5>February 2, 2023</h5>

      <p>PASADENA, CA—Sandra Cauffman, Chair of the INCUS Science Review Board (SRB), has stated in the INCUS SRB preliminary report that  “The SRB conclusion is that the project has passed the SRR/MDR [Science Readiness Review/Mission Design Review].” </p>

      <p>The SRR/MDR process is a key step in progressing past <Link to="../timeline">Phase A</Link>, the concept and technology development phase, and into Phase B, the preliminary design and technology completion phase. The SRR/MDR board met with key members of the INCUS team at JPL; this meeting included discussions on the science and engineering of the INCUS mission.</p>

      <p>Congratulations to all of the INCUS team members in successfully passing the SRR/MDR!</p>



    </Layout >

  )
}

export default IncusPassesSRRMDR

